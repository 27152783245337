import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/script',
    name: 'script',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ScriptView.vue')
  },
  {
    path: '/exercises',
    name: 'exercises',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ExerciseView.vue')
  },
  {
    path: '/chat',
    name: 'chat',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ChatView.vue')
  },
  {
    path: '/qa',
    name: 'qa',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/QnAView.vue')
  },
  {
    path: '/mitschreiben',
    name: 'mitschreiben',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MitschreibeView.vue')
  },
  {
    path: '/announcements',
    name: 'announcements',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AnnouncementView.vue')
  },
  {
    path: '/datenschutzerklaerung',
    name: 'datenschutzerklaerung',
    component: () => import('../views/DatenschutzView.vue')
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: () => import('../views/ImpressumView.vue')
  },
  {
    path: '/ue1wordcloud',
    name: 'ue1wordcloud',
    component: () => import('../views/content/UE1_Wordcloud.vue')
  },
  {
    path: '/ue2miro',
    name: 'ue2miro',
    component: () => import('../views/content/UE2_Miro.vue')
  },
]

const router = new VueRouter({
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
  },
  routes
})

export default router
